@import "styles/main.scss";
#Button {
    .container {
    }
}
.root {
    height: 40px !important;
    font-weight: bold !important;
    border-radius: 10px !important;
    text-transform: unset !important;
}
.primary {
    // color: var(--primary) !important;
}
.secondary {
    // color: var(--secondary-dark) !important;
}