@import "./main";

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  overflow: hidden;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
    color: var(--text);
}

a {
  color: inherit;
  text-decoration: none;
}
p {
  margin: 0;
}
.h {
  font-weight: 450;
  margin: 0;
}
h1 {
  font-size: $h1;
  @extend .h;
}
h2 {
  font-size: $h2;
  @extend .h;
}
h3 {
  font-size: $h3;
  @extend .h;
}
h4 {
  font-size: $h4;
  @extend .h;
}
h5 {
  @extend .h;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 19px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: solid 3px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--background);
  border-radius: 20px;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.35);
  border: solid 3px transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--border);
  opacity: 0.5;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: #e3e3e3 !important;
}

.rti--container {
  margin-top: 10px;
}

.react-tagsinput-tag {
  background-color: white !important;
  border-radius: 13px;
  border: 1px solid #a2a2a2;
  color: #5f5f5f;
  padding: 7px 8px;
}

.react-tagsinput--focused {
  border-color: #4d13b7;
}

.premiumLabel::after {
  content: url("../../public/assets/icon/golden-crown-label.svg");
  position: relative;
  top: 2px;
  left: 4px;
}
