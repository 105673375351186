// @import "react-big-calendar/lib/sass/styles";

.rbc-today {
    background-color: $background;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    background-color: $secondary-light;
    margin: 8px;
}

.rbc-toolbar-label {
    font-size: 1.5rem;
    color: var(--secondary);
    text-transform: uppercase;
}

.rbc-time-header-content {
    border: none;
}

.rbc-calendar {
}
.rbc-time-content {
    > * + * > * {
        border-left: none;
    }
}

.rbc-header {
    border: none;
}

.rbc-time-view {
    border: none;
}
