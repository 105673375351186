$translateArrow: -18px;
$translateArrow2: 10px;
.conffetiContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 100;
    // background-color: white;
}

#TutorialCard {
    display: flex;
    width: calc(100vw - 32px);
    
    border-radius: 16px;

    .paper {
        overflow: visible;
        max-width: 400px;
        border-radius: 12px;
        margin: 0;
        border:2px solid var(--primary-300);
    }
    .header {
        position: relative;
        border-radius: 10px 10px 0 0;
        display: flex;
        background-color: #F3EDFF;
        color: var(--primary);
        font-size: large;
        font-weight: bold;
        justify-content: space-between;
        span {
            display: flex;
            align-items: center;
            padding: 12px;
        }
        img {
            margin-top: 8px;
        }

    }
    .container {
        padding: 16px;
        font-size: 14px;
        color: #5F5F5F;
    }
    .action {
        padding: 16px;
    }
    .arrow {
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url("./arrow.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .arrowTopLeft {
        @extend .arrow;
        top: $translateArrow;
        left: $translateArrow2;
    }
    .arrowTopCenter {
        @extend .arrow;
        top: $translateArrow;
        left: calc(50% - 10px);
    }
    .arrowTopRight {
        @extend .arrow;
        top: $translateArrow;
        right: $translateArrow2;
    }
    .arrowBottomLeft {
        @extend .arrow;
        bottom: $translateArrow;
        left: $translateArrow2;
        transform: rotate(-180deg);
    }
    .arrowBottomCenter {
        @extend .arrow;
        bottom: $translateArrow;
        left: calc(50% - 10px);
        transform: rotate(180deg);
    }
    .arrowBottomRight {
        @extend .arrow;
        bottom: $translateArrow;
        right: $translateArrow2;
        transform: rotate(180deg);
    }
    .arrowLeftTop {
        @extend .arrow;
        top: $translateArrow2;
        left: $translateArrow;
        transform: rotate(-90deg);
    }
    .arrowLeftCenter {
        @extend .arrow;
        top: calc(50% - 10px);
        left: $translateArrow;
        transform: rotate(-90deg);
    }
    .arrowLeftBottom {
        @extend .arrow;
        bottom: $translateArrow2;
        left: $translateArrow;
        transform: rotate(-90deg);
    }
    .arrowRightTop {
        @extend .arrow;
        top: $translateArrow2;
        right: $translateArrow;
        transform: rotate(90deg);
    }
    .arrowRightCenter {
        @extend .arrow;
        top: calc(50% - 10px);
        right: $translateArrow;
        transform: rotate(90deg);
    }
    .arrowRightBottom {
        @extend .arrow;
        bottom: $translateArrow2;
        right: $translateArrow;
        transform: rotate(90deg);
    }

}
