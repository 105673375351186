$primary: var(--primary);
$primary-light: var(--primary-light);
$primary-dark: var(--primary-dark);
$secondary: var(--secondary);
$secondary-light: var(--secondary-light);
$secondary-dark: var(--secondary-dark);
$tertiary: var(--tertiary);
$tertiary-light: var(--tertiary-light);
$alert: var(--alert);
$positive: var(--positive);
$banner: var(--banner);
$cancel: var(--cancel);
$text: var(--text);
$placeholder: var(--placeholder);
$disable: var(--disable);
$accent: var(--primary);
$onPrimary: var(--on-primary);
$onSecondary: var(--on-secondary);
$onTertiary: var(--on-tertiary);
$background: var(--background);
$disabled: var(--disabled);
$sucess: var(--sucess);
$warning: var(--warning);
$erro: var(--erro);
$input-unfocus: "gray";

@function colorOn($color) {
    @if lightness($color) >70 {
        @return $color-on-light;
    } @else {
        @return $color-on-dark;
    }
}
